import { useState } from 'react';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import style from './ParametersListColumn.module.scss';
import DragAndDropList from 'components/DragAndDropList/DragAndDropList';
import { ButtonField, Icon } from '@wk/components-v3-react16';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectParameterList,
  selectSelectedParameter,
} from 'redux/createModule/CreateModuleSlice';
import { IModuleParameterItem } from 'interfaces/modules/module.interface';
import {
  addParameter,
  setParameterList,
  selectParameter,
} from 'redux/createModule/CreateModuleSlice';

const ParametersListColumn = () => {
  const parameterList = useAppSelector(
    selectParameterList
  ) as IModuleParameterItem[];
  const selectedParameter = useAppSelector(
    selectSelectedParameter
  ) as IModuleParameterItem;

  const [counter, setCounter] = useState<number>(parameterList.length);

  const dispatch = useAppDispatch();

  const handleAddParameter = () => {
    dispatch(
      addParameter({
        id: `item-${counter + 1}`,
        paramName: '',
      })
    );
    setCounter((prev) => prev + 1);
  };

  const handleOnDrop = (newList: IModuleParameterItem[]) => {
    dispatch(setParameterList(newList));
  };

  const handleOnSelect = (newItem: IModuleParameterItem) => {
    dispatch(selectParameter(newItem));
  };

  return (
    <CsbErrorBoundary>
      <div data-testid="ParametersListColumn" className={style['container']}>
        <div>
          <span className={style['title']}>Module parameters</span>
          <div className={style['parameter-list']}>
            <DragAndDropList
              items={parameterList}
              onDrop={handleOnDrop}
              selectedItemId={selectedParameter?.id}
              onSelect={handleOnSelect}
              labelKey="paramName"
              defaultDisplayLabel="New parameter name"
            />
          </div>
        </div>
        <div className={style['button-container']}>
          <ButtonField type="secondary" size="large">
            <button onClick={handleAddParameter} type="button">
              <Icon name="wk-icon-plus"></Icon>
              <span>Add parameter</span>
            </button>
          </ButtonField>
        </div>
      </div>
    </CsbErrorBoundary>
  );
};

export default ParametersListColumn;
