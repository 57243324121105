import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import style from './ParametersDetailsColumn.module.scss';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectSelectedParameter,
  updateParameterProps,
} from 'redux/createModule/CreateModuleSlice';
import { IModuleParameterItem } from 'interfaces/modules/module.interface';
import { ButtonField, Icon, TextField } from '@wk/components-v3-react16';
import { SelectField } from '@wk/components-react16';
import { MODULE_PARAMETER_TYPES } from 'utils/common-constants';

const ParametersDetailsColumn = () => {
  const dispatch = useAppDispatch();
  const selectedParameter = useAppSelector(
    selectSelectedParameter
  ) as IModuleParameterItem;

  const handleSelect = (event: any) => {
    dispatch(
      updateParameterProps({
        id: selectedParameter.id,
        newSelected: { ...selectedParameter, type: event.target.value },
      })
    );
  };

  const handleInputChange = ({ target }: any) => {
    dispatch(
      updateParameterProps({
        id: selectedParameter.id,
        newSelected: { ...selectedParameter, [target.id]: target.value },
      })
    );
  };

  return (
    <CsbErrorBoundary>
      <div data-testid="ParametersDetailsColumn" className={style['container']}>
        {selectedParameter?.id && (
          <>
            <div className={style['actions-container']}>
              <ButtonField type="icon">
                <button type="button" className={style['action-button']}>
                  <Icon name="wk-icon-folder-from"></Icon>
                  <span>Duplicate parameter</span>
                </button>
              </ButtonField>
              <ButtonField type="icon">
                <button type="button" className={style['action-button']}>
                  <Icon name="wk-icon-trash"></Icon>
                  <span>Delete</span>
                </button>
              </ButtonField>
            </div>
            <SelectField label="Parameter type" labelFor="paramSelect">
              <select
                id="paramSelect"
                name="paramSelect"
                value={(selectedParameter.type as string) || ''}
                onChange={handleSelect}
              >
                <option value="">Select parameter type</option>
                {MODULE_PARAMETER_TYPES.map(({ label, value }) => (
                  <option key={label} value={value} label={label}>
                    {label}
                  </option>
                ))}
              </select>
            </SelectField>
            {selectedParameter?.type && (
              <TextField label="Parameter display name" labelFor="paramName">
                <input
                  data-testid="ParametersDetailsParamName"
                  placeholder="Enter the parameter display name"
                  id="paramName"
                  type="text"
                  value={selectedParameter?.paramName}
                  onChange={handleInputChange}
                />
              </TextField>
            )}
            {!selectedParameter?.type && (
              <div className={style['info-container']}>
                <span className={style['info-title']}>
                  Info about parameter type
                </span>
                {MODULE_PARAMETER_TYPES.map(({ label, description }) => (
                  <div>
                    <span className={style['info-subtitle']}>{label}</span>
                    <p className={style['info-description']}>{description}</p>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </CsbErrorBoundary>
  );
};

export default ParametersDetailsColumn;
